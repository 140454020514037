<template>
  <CompleteTable
    :sorted-field="sortedField"
    :data="alerts.data.data"
    :sort-mapping="sortMapping"
    :title="'send notifications'"
    :total="alerts.total"
    :filter-path="'alerts/filterSendAlerts'"
  >
    <template v-slot="{field, showInfoSheet}">
      <td
        class="text-end"
      >
        <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <i data-feather="more-vertical" />
          </template>
          <div
            class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
          >
            <b-dropdown-item @click="showInfoSheet(field)">
              <i
                class="me-50"
                data-feather="eye"
              /> View receivers
            </b-dropdown-item>
            <div class="dropdown-divider" />
            <b-dropdown-item @click="duplicate(field)">
              <i
                class="me-50"
                data-feather="copy"
              /> Duplicate
            </b-dropdown-item>
            <b-dropdown-item
              v-if="field.draft"
              @click="editDraft(field)"
            >
              <i
                class="me-50"
                data-feather="edit"
              /> Edit draft
            </b-dropdown-item>
            <b-dropdown-item
              @click="deleteAlert(field)"
            >
              <i
                class="me-50"
                data-feather="trash"
              /> Delete
            </b-dropdown-item>
          </div>
        </b-dropdown>
        <a
          href=""
          class="btn btn-icon btn-light-secondary d-block d-sm-none"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasMenu"
          aria-controls="offcanvasBottom"
        ><i
          data-feather="more-vertical"
        /></a>
      </td>
    </template>
    <template #customfilters>
      <div class="mb-1">
        <label
          for="name"
          class="form-label"
        >Type</label>
        <v-select
          v-model="filters.type"
          label="label"
          :options="[
            {label: 'Task', value: 1},
            {label: 'Alarm', value: 2},
          ]"
          :get-option-key="option => option.value"
          @input="saveFilter({ customLabel: true, name: `Type: ${$event.label}`, value: $event }, 'type')"
        />
      </div>
      <div class="mb-1">
        <label
          for="name"
          class="form-label"
        >Title</label>
        <input
          id="dateOpen"
          v-model="filters.title"
          type="text"
          class="form-control"
          @change="saveFilter({ customLabel: true, name: `Title: ${$event.target.value}`, value: $event.target.value }, 'title')"
        >
      </div>
      <div class="mb-1">
        <label
          for="name"
          class="form-label"
        >Send by</label>
        <v-select
          v-model="filters.send_by"
          label="name"
          :options="users"
          :get-option-key="option => option.id"
          @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
          @input="saveFilter({ customLabel: true, name: `Send by: ${$event.name}`, value: $event }, 'send_by')"
        />
      </div>
      <div class="mb-1">
        <label
          for="name"
          class="form-label"
        >State</label>
        <v-select
          v-model="filters.draft"
          label="label"
          :options="[
            {label: 'Draft', value: true},
            {label: 'Send', value: false},
          ]"
          :get-option-key="option => option.value"
          @input="saveFilter({ customLabel: true, name: `State: ${$event.label}`, value: $event }, 'draft')"
        />
      </div>
      <div class="mb-1">
        <label
          for="name"
          class="form-label"
        >Notified</label>
        <v-select
          v-model="filters.notified"
          label="label"
          multiple
          :options="[
            {label: 'Intranet', value: 'send_intranet'},
            {label: 'Email', value: 'send_email'},
            {label: 'Calendar', value: 'show_calendar'},
          ]"
          :get-option-key="option => option.value"
          @input="saveFilter({ customLabel: true, name: `Notified: ${$event ? ($event.map(e => e.label)).join(' - ') : ''}`, value: $event }, 'notified')"
        />
      </div>
    </template>
    <template #info-sheet-item="{item}">
      <div class="offcanvas-body offcanvas-body--view">
        <div class="mb-1">
          <p>Receivers</p>
          <table class="table">
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="receiver in item.receivers"
                :key="receiver.id"
              >
                <td>{{ receiver.true_name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </CompleteTable>
</template>

<script>
import { mapGetters } from 'vuex'
import CompleteTable from '@/views/back/partials/components/CompleteTable.vue'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import Vue from 'vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      sortedField: 'Type',
      defaultFields: [
        {
          name: 'Type',
          checked: true,
          order: 1,
        },
        {
          name: 'Title',
          checked: true,
          order: 2,
        },
        {
          name: 'Send by',
          checked: true,
          order: 3,
        },
        {
          name: 'Alert date',
          checked: true,
          order: 4,
        },
        {
          name: 'Receivers',
          checked: true,
          order: 5,
        },
        {
          name: 'Notified',
          checked: true,
          order: 6,
        },
        {
          name: 'Files',
          checked: true,
          order: 7,
        },
        {
          name: 'Checked',
          checked: true,
          order: 8,
        },
        {
          name: 'Reminders',
          checked: true,
          order: 9,
        },
        {
          name: 'State',
          checked: true,
          order: 10,
        },
      ],
      sortMapping: {
        Type: 'type.name',
        Title: 'title',
        'Send by': 'send_by',
        'Alert date': 'alert_date',
        Receivers: 'receivers.length',
        Notified: 'notified',
        Files: 'files.length',
        Checked: 'checked',
        Reminders: 'reminders.length',
        State: 'state',
      },
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      alerts: 'alerts/allAlerts',
      filters: 'filters/filters',
      users: 'users/fakeUsers',
    }),
  },
  async mounted() {
    this.$store.dispatch('alerts/filterSendAlerts', this.filters)
    await this.$store.dispatch('modals/fetchUserFields', 'send_alerts_table')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'send_alerts_table',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if (s.name !== '') {
        await this.$store.dispatch(store, s)
      }
    },
    async getAlert(id) {
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$alertUrl}/alerts/${id}`)
      return resp.data.data
    },
    async editDraft(data) {
      const alert = await this.getAlert(data.id)
      await this.$store.dispatch('alerts/saveAlertEdit', alert)
      this.$store.dispatch('modals/toggleCreateItem', true)
    },
    async duplicate(data) {
      const alert = await this.getAlert(data.id)
      delete alert.id
      await this.$store.dispatch('alerts/saveAlertEdit', alert)
      this.$store.dispatch('modals/toggleCreateItem', true)
    },
    async deleteAlert(data) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'alerts/filterSendAlerts', url: `${Vue.prototype.$groupUrl}/alerts/${data.id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
