var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CompleteTable',{attrs:{"sorted-field":_vm.sortedField,"data":_vm.alerts.data.data,"sort-mapping":_vm.sortMapping,"title":'send notifications',"total":_vm.alerts.total,"filter-path":'alerts/filterSendAlerts'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var field = ref.field;
var showInfoSheet = ref.showInfoSheet;
return [_c('td',{staticClass:"text-end"},[_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","toggle-class":"p-0","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{attrs:{"data-feather":"more-vertical"}})]},proxy:true}],null,true)},[_c('div',{staticClass:"btn-group dropup dropdown-icon-wrapper d-none d-sm-block"},[_c('b-dropdown-item',{on:{"click":function($event){return showInfoSheet(field)}}},[_c('i',{staticClass:"me-50",attrs:{"data-feather":"eye"}}),_vm._v(" View receivers ")]),_c('div',{staticClass:"dropdown-divider"}),_c('b-dropdown-item',{on:{"click":function($event){return _vm.duplicate(field)}}},[_c('i',{staticClass:"me-50",attrs:{"data-feather":"copy"}}),_vm._v(" Duplicate ")]),(field.draft)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.editDraft(field)}}},[_c('i',{staticClass:"me-50",attrs:{"data-feather":"edit"}}),_vm._v(" Edit draft ")]):_vm._e(),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteAlert(field)}}},[_c('i',{staticClass:"me-50",attrs:{"data-feather":"trash"}}),_vm._v(" Delete ")])],1)]),_c('a',{staticClass:"btn btn-icon btn-light-secondary d-block d-sm-none",attrs:{"href":"","data-bs-toggle":"offcanvas","data-bs-target":"#offcanvasMenu","aria-controls":"offcanvasBottom"}},[_c('i',{attrs:{"data-feather":"more-vertical"}})])],1)]}},{key:"customfilters",fn:function(){return [_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"name"}},[_vm._v("Type")]),_c('v-select',{attrs:{"label":"label","options":[
          {label: 'Task', value: 1},
          {label: 'Alarm', value: 2} ],"get-option-key":function (option) { return option.value; }},on:{"input":function($event){return _vm.saveFilter({ customLabel: true, name: ("Type: " + ($event.label)), value: $event }, 'type')}},model:{value:(_vm.filters.type),callback:function ($$v) {_vm.$set(_vm.filters, "type", $$v)},expression:"filters.type"}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"name"}},[_vm._v("Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.title),expression:"filters.title"}],staticClass:"form-control",attrs:{"id":"dateOpen","type":"text"},domProps:{"value":(_vm.filters.title)},on:{"change":function($event){return _vm.saveFilter({ customLabel: true, name: ("Title: " + ($event.target.value)), value: $event.target.value }, 'title')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.filters, "title", $event.target.value)}}})]),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"name"}},[_vm._v("Send by")]),_c('v-select',{attrs:{"label":"name","options":_vm.users,"get-option-key":function (option) { return option.id; }},on:{"search":function($event){return _vm.onSearch({ name: $event }, 'users/fetchFakeUsers')},"input":function($event){return _vm.saveFilter({ customLabel: true, name: ("Send by: " + ($event.name)), value: $event }, 'send_by')}},model:{value:(_vm.filters.send_by),callback:function ($$v) {_vm.$set(_vm.filters, "send_by", $$v)},expression:"filters.send_by"}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"name"}},[_vm._v("State")]),_c('v-select',{attrs:{"label":"label","options":[
          {label: 'Draft', value: true},
          {label: 'Send', value: false} ],"get-option-key":function (option) { return option.value; }},on:{"input":function($event){return _vm.saveFilter({ customLabel: true, name: ("State: " + ($event.label)), value: $event }, 'draft')}},model:{value:(_vm.filters.draft),callback:function ($$v) {_vm.$set(_vm.filters, "draft", $$v)},expression:"filters.draft"}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"name"}},[_vm._v("Notified")]),_c('v-select',{attrs:{"label":"label","multiple":"","options":[
          {label: 'Intranet', value: 'send_intranet'},
          {label: 'Email', value: 'send_email'},
          {label: 'Calendar', value: 'show_calendar'} ],"get-option-key":function (option) { return option.value; }},on:{"input":function($event){_vm.saveFilter({ customLabel: true, name: ("Notified: " + ($event ? ($event.map(function (e) { return e.label; })).join(' - ') : '')), value: $event }, 'notified')}},model:{value:(_vm.filters.notified),callback:function ($$v) {_vm.$set(_vm.filters, "notified", $$v)},expression:"filters.notified"}})],1)]},proxy:true},{key:"info-sheet-item",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"offcanvas-body offcanvas-body--view"},[_c('div',{staticClass:"mb-1"},[_c('p',[_vm._v("Receivers")]),_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Name")])])]),_c('tbody',_vm._l((item.receivers),function(receiver){return _c('tr',{key:receiver.id},[_c('td',[_vm._v(_vm._s(receiver.true_name))])])}),0)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }