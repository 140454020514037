<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              @click="$store.dispatch('modals/toggleCreateItem', true)"
            ><i data-feather="plus" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <b-tabs lazy>
          <b-tab
            active
          >
            <template #title>
              General notifications <span class="ms-1 badge bg-info">{{ general.length }}</span>
            </template>

            <div class="card">
              <div class="card-body ">
                <GeneralNotifications @showAlert="showAlert" />
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              Pending of authorization <span class="ms-1 badge bg-info">{{ notisCount.pendingAuth }} / {{ notisCount.otherNoti }}</span>
            </template>

            <PendingAuthNotifications @showAlert="showAlert" />
            <OtherNotifications @showAlert="showAlert" />
          </b-tab>
          <b-tab>
            <template #title>
              Archived <span class="ms-1 badge bg-info">{{ notisCount.archiveds }}</span>
            </template>

            <ArchivedNotifications @showAlert="showAlert" />
          </b-tab>
          <b-tab v-if="loggedUser.roles.includes('super-admin')">
            <template #title>
              Send notifications <span class="ms-1 badge bg-warning">{{ notisCount.draft }} in draft</span>
            </template>

            <SendAlertsTable />
          </b-tab>
          <b-tab :title-item-class="'ms-auto'">
            <template #title>
              Tasks <span class="ms-1 badge bg-info">{{ notisCount.tasks }}</span>
            </template>

            <TaskTab @showAlert="showAlert" />
          </b-tab>
        </b-tabs>
        <ItemInfoSheet :table-id="87">
          <template #info-sheet-title>
            Read notification
          </template>
          <template #info-sheet>
            <div class="offcanvas-body">
              <div class="mb-1 offcanvas-body--view--title">
                <p>Send<br>
                  <strong>{{ active.date }}</strong></p>
              </div>
              <hr>
              <div class="mb-1 offcanvas-body--view--title">
                <p>Subject<br>
                  <strong>{{ active.title }}</strong></p>
              </div>
              <div class="mb-1">
                <p>Message<br>
                  <strong v-html="active.message" />
                </p>
              </div>
              <div
                v-if="active.files && active.files.length > 0"
                class="mb-1"
              >
                <p>Attached file(s)<br>
                  <template v-for="file in active.files">
                    <a
                      :key="file.id"
                      :href="file.url"
                      title="open file"
                      target="_blank"
                    >
                      <i data-feather="eye" /> {{ file.name }}
                    </a>
                    <br>
                  </template>
                </p>
              </div>
            </div>
            <div class="offcanvas-footer mt-auto">
              <a
                title="apply actions"
                class="btn btn-dark mb-1 d-grid w-100"
                @click="$store.dispatch('modals/toggleInfoItemSheet', false)"
              >Close</a>
            </div>
          </template>
        </ItemInfoSheet>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import Vue from 'vue'
import 'vue2-datepicker/index.css'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import GeneralNotifications from '../dashboard/components/GeneralNotifications.vue'
import OtherNotifications from '../dashboard/components/OtherNotifications.vue'
import SendAlertsTable from '../dashboard/components/SendAlertsTable.vue'
import PendingAuthNotifications from '../dashboard/components/PendingAuthNotifications.vue'
import ArchivedNotifications from '../dashboard/components/ArchivedNotifications.vue'
import TaskTab from './components/TaskTab.vue'

export default {
  components: {
    GeneralNotifications,
    OtherNotifications,
    PendingAuthNotifications,
    ArchivedNotifications,
    SendAlertsTable,
    ItemInfoSheet,
    TaskTab,
    BTab,
    BTabs,
  },
  data() {
    return {
      sortedField: 'Title',
      defaultFields: [
        {
          name: 'Title',
          checked: true,
          order: 1,
        },
        {
          name: 'Message',
          checked: true,
          order: 2,
        },
        {
          name: 'Is read',
          checked: true,
          order: 3,
        },
        {
          name: 'Source',
          checked: true,
          order: 4,
        },
        {
          name: 'Type',
          checked: true,
          order: 5,
        },
        {
          name: 'Date',
          checked: true,
          order: 6,
        },
      ],
      sortMapping: {
        Title: 'title',
        Message: 'message',
        'Is read': 'read',
        Source: 'source',
        Type: 'type.name',
        Date: 'begin_date',
      },
      title: 'Notifications & Tasks',
      active: {},
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      general: 'alerts/general',
      pending: 'alerts/pendingAuth',
      notisCount: 'alerts/notisCount',
      tasks: 'alerts/tasks',
      others: 'alerts/others',
      filters: 'filters/filters',
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'alerts-table')
    await this.$store.dispatch('alerts/fetchSent', this.filters)
    await this.$store.dispatch('alerts/fetchAlertData')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'alerts-table',
      })
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    showAlert(alert) {
      this.active = alert
      this.$store.dispatch('modals/toggleTableClicked', 87)
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
    },
    async read(alert) {
      if (!alert.read) {
        await this.$store.dispatch('alerts/read', alert.id)
        this.$store.dispatch('alerts/fetchSent', this.filters).then(() => {
          setTimeout(() => {
            // eslint-disable-next-line no-undef
            feather.replace({
              width: 14,
              height: 14,
            })
          }, 50)
        })
        this.$store.dispatch('alerts/fetchPendingToRead')
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'alerts/fetchSent', url: `${Vue.prototype.$groupUrl}/alerts/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
