<template>
  <CompleteTable
    :sorted-field="sortedField"
    :data="items.data.data"
    :sort-mapping="sortMapping"
    :title="'tasks'"
    :total="items.total"
    :fetch-path="'-'"
    :filter-path="'alerts/fetchTasks'"
    :actions="true"
    @selectedItems="saveSelectedItems"
  >
    <template v-slot="{field}">
      <td
        class="text-end"
      >
        <a
          class="btn btn-icon btn-flat-secondary"
          @click="$emit('showAlert', field)"
        ><i data-feather="eye" /></a>
      </td>
    </template>
    <template #customfilters>
      <div
        v-if="hasAdminPermission('view publications')"
        class="mb-1"
      >
        <label
          for="nameseniorcall"
          class="form-label"
        >Researcher</label>
        <v-select
          v-model="filters.researcher"
          label="name"
          :options="users"
          :get-option-key="option => option.id"
          @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
          @input="saveFilter({ customLabel: true, name: `Researcher: ${$event.name}`, value: $event }, 'researcher')"
        />
        <hr>
      </div>
      <div class="mb-1">
        <label
          for="areaseniorcall"
          class="form-label"
        >Title</label>
        <input
          v-model="filters['title']"
          type="text"
          class="form-control"
          @change="saveFilter({ customLabel: true, name: `Title: ${$event.target.value}`, value: `${$event.target.value}` }, 'title')"
        >
      </div>
      <div class="mb-1">
        <label
          for="areaseniorcall"
          class="form-label"
        >Send by</label>
        <v-select
          v-model="filters.send_by"
          label="name"
          :options="users"
          :get-option-key="option => option.id"
          @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
          @input="saveFilter({ customLabel: true, name: `Send by: ${$event.name}`, value: $event }, 'send_by')"
        />
      </div>
      <div class="mb-1">
        <label
          for="nameseniorcall"
          class="form-label"
        >Alert date</label>
        <br>
        <date-picker
          v-model="filters['alert_date']"
          format="D MMM Y"
          value-type="format"
          class="w-100"
          @change="saveFilter({ customLabel: true, name: `Alert date: ${$event}`, value: $event }, 'alert_date')"
        />
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >With document</label>
        <v-select
          v-model="filters['with_documents']"
          label="name"
          :options="[
            { name: 'Yes', value: true },
            { name: 'No', value: false },
            { name: 'All', value: null },
          ]"
          :get-option-key="option => option"
          @input="saveFilter({ customLabel: true, name: `With documents: ${$event.name}`, value: $event }, 'with_documents')"
        />
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Read</label>
        <v-select
          v-model="filters['read']"
          label="name"
          :options="[
            { name: 'Yes', value: true },
            { name: 'No', value: false },
            { name: 'All', value: null },
          ]"
          :get-option-key="option => option"
          @input="saveFilter({ customLabel: true, name: `Read: ${$event.name}`, value: $event }, 'read')"
        />
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Done</label>
        <v-select
          v-model="filters['done']"
          label="name"
          :options="[
            { name: 'Yes', value: true },
            { name: 'No', value: false },
            { name: 'All', value: null },
          ]"
          :get-option-key="option => option"
          @input="saveFilter({ customLabel: true, name: `Done: ${$event.name}`, value: $event }, 'done')"
        />
      </div>
    </template>
    <template #info-sheet-item>
      <div class="offcanvas-body offcanvas-body--view">

        <h4 class="mb-2">
          <strong class="text-success">{{ selectedItems.length }}</strong> selected registers
        </h4>

        <ul
          id="basic-list-group"
          class="list-group"
        >
          <li class="list-group-item draggable">
            <div class="d-flex">
              <div class="more-info">
                <div class="form-check">
                  <input
                    id="tableAction1"
                    v-model="actions.archive"
                    type="checkbox"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label"
                    for="tableAction1"
                  >Archive</label>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="offcanvas-footer mt-auto">
        <a
          href="#"
          title="apply actions"
          class="btn btn-primary mb-1 d-grid w-100"
          @click="applyActions"
        >Apply actions</a>
      </div>
    </template>
  </CompleteTable>
</template>

<script>
import { mapGetters } from 'vuex'
import CompleteTable from '@/views/back/partials/components/CompleteTable.vue'
import Vue from 'vue'

export default {
  components: {
    CompleteTable,
  },
  data() {
    return {
      actions: {},
      userId: this.$route.params.id,
      loading: false,
      selectedItems: [],
      clicked: false,
      sortedField: 'Title',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
          only_admin: true,
        },
        {
          name: 'Title',
          checked: true,
          order: 2,
        },
        {
          name: 'Send by',
          checked: true,
          order: 3,
        },
        {
          name: 'Alert date',
          checked: true,
          order: 4,
        },
        {
          name: 'Files',
          checked: true,
          order: 5,
        },
        {
          name: 'Read',
          checked: true,
          order: 6,
        },
        {
          name: 'Do',
          checked: true,
          order: 7,
        },
      ],
      sortMapping: {
        Researcher: 'researcher.name',
        Title: 'title',
        'Send by': 'send_by',
        'Alert date': 'alert_date',
        Files: 'files.length',
        Read: 'read',
        Do: 'done',
      },
    }
  },
  computed: {
    ...mapGetters({
      items: 'alerts/tasks',
      filters: 'filters/filters',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      fields: 'modals/tableSettingsFields',
    }),
  },
  async mounted() {
    await this.$store.dispatch('alerts/fetchTasks', this.filters)
    await this.$store.dispatch('modals/fetchUserFields', 'tasks')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'tasks',
      })
    }
    feather.replace({
      width: 14,
      height: 14,
    })
  },
  methods: {
    saveFilter(value, field) {
      this.filters[field] = value
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    applyActions() {
      if (this.selectedItems.length > 0) {
        Vue.swal({
          title: 'Do you want to archive the selected tasks?',
          html: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
        }).then(async result => {
          if (result.isConfirmed) {
            this.selectedItems.forEach(async e => {
              await this.$store.dispatch('alerts/archive', e.alert_id)
            })

            this.loading = true
            await this.$store.dispatch('alerts/fetchTasks', this.filters)
            this.loading = false

            feather.replace({
              width: 14,
              height: 14,
            })
            this.$store.dispatch('modals/toggleInfoItemSheet', false)
            this.selectedItems = []
          }
        })
      } else {
        this.$toastr.error('', 'Please, select any task before applying')
      }
    },
    selectAll(event) {
      if (event.target.checked) {
        this.selectedItems = this.items
      } else {
        this.selectedItems = []
      }
    },
    select(click, item) {
      if (click) {
        this.selectedItems.push(item)
      } else {
        this.selectedItems.splice(this.selectedItems.findIndex(x => x.id === item.id), 1)
      }
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
  },
}
</script>
